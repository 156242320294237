import { useEffect, useState, useRef } from "react";
import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
  Badge,
  Loader,
  SimpleGrid,
  MediaQuery,
} from "@mantine/core";
import { ChevronRight } from "react-feather";
import Autocomplete from "@mui/material/Autocomplete";
import { Link, useNavigate } from "@tanstack/react-location";
import HomeLayout from "../layouts/HomeLayout";
import AppointmentCard, { EmptyAppointment } from "../components/appointment";
import { useServices, useUserServices } from "../services/services";
import thyrocare from "../assets/labs/thyrocare.jpeg";
import redcliff from "../assets/labs/redcliff.webp";
import banner from "../assets/bannerAtkins.svg";
import mobileLogo from "../assets/mobileAtkins.svg";
import mobileBanner from "../assets/AtkinMobileBanner.svg";
import colaLogo from "../assets/colaLogo.jpg";
import mobileColaLogo from "../assets/mobileColaLogo.svg";
import cocaColaMobileBanner from "../assets/cocaColaMobileBanner.svg";
import ceatLogo from "../assets/ceatLogo.svg";
import mobileCeatLogo from "../assets/mobileCeatLogo.svg";
import ceatBanner from "../assets/ceatBanner.svg";
import ceatMobileBanner from "../assets/ceatMobileBanner.svg";
import britishTelecomBanner from "../assets/britishTelecomBanner.jpg";
import britishTelecomMobileBanner from "../assets/britishTelecomMobileBanner.jpg";
import ceatTests from "../assets/ceatTests.svg";
import technipBanner from "../assets/technipBanner.jpg";
import technipTests from "../assets/technipTests.jpg";
import technipMobileBanner from "../assets/technipMobileBanner.jpg";
import technipBanner2 from "../assets/technipBanner2.jpg";
import technipMobileBanner2 from "../assets/technipMobileBanner2.jpg";
import technip2Modal from "../assets/technip2Modal.jpg";
import servierBanner from "../assets/servierBanner.png";
import servierMobileBanner from "../assets/servierMobileBanner.png";
import servierModal from "../assets/servierModal.jpg";
import jabilLogo from "../assets/jabilLogo.png";
import jabilBanner from "../assets/jabilBanner.jpg";
import jabilMobileBanner from "../assets/jabilMobileBanner.jpg";
import novoBanner from "../assets/novoBanner.jpg";
import novoMobileBanner from "../assets/novoMobileBanner.jpg";
import novoLogo from "../assets/novoLogo.png";
import heroLogo from "../assets/heroLogo.png";
import heroBanner from "../assets/heroBanner.png";
import heroMobileBanner from "../assets/heroMobileBanner.png";
import heroTest from "../assets/heroTest.png";
import manCard from "../assets/manCard.png";
import womenCard from "../assets/womenCard.png";
import noService from "../assets/noService.png";
import rsmLogo from "../assets/rsmLogo.png";
import rsmMobileBanner from "../assets/rsmMobileBanner.jpg";
import rsmBanner from "../assets/rsmBanner.jpg";
import rsmTest from "../assets/rsmTest.png";
import sentineBanner from "../assets/sentineBanner.png";
import sentineMobileBanner from "../assets/sentineMobileBanner.png";
import sentineTest from "../assets/sentineTests.png";
import dhlLogo from "../assets/dhlLogo.jpg";
import dhlBanner from "../assets/dhlBanner.jpg";
import dhlMobileBanner from "../assets/dhlMobileBanner.jpg";
import dhlTest from "../assets/dhlTest.png";
import dhlBanner2 from "../assets/dhlBanner2.png";
import dhlMobileBanner2 from "../assets/dhlMobileBanner2.png";
import sonyLogo from "../assets/sonyLogo.png";
import sonyBanner from "../assets/sonyBanner.jpg";
import sonyTest from "../assets/sonyTest.jpg";
import sonyMobileBanner from "../assets/sonyMobileBanner.jpg";
import tataLogo from "../assets/tataLogo.jpg";
import tataBanner from "../assets/tataBanner.jpg";
import tataMobileBanner from "../assets/tataMobileBanner.jpg";
import tataTest from "../assets/tataTest.jpg";
import rahejaLogo from "../assets/rahejaLogo.jpg";
import rahejaBanner from "../assets/rahejaBanner.jpg";
import rahejaMobileBanner from "../assets/rahejaMobileBanner.jpg";
import rahejaTest from "../assets/rahejaTest.jpg";
import tataPowerLogo from "../assets/tataPowerLogo.png";
import herbalifeLogo from "../assets/herbalifeLogo.jpg";
import herbalifeBanner from "../assets/herbalifeBanner.jpg";
import herbalifeMobileBanner from "../assets/herbalifeMobileBanner.jpg";
import herbalifeTest from "../assets/herbalifeTest.jpg";
import blueDartLogo from "../assets/blueDartLogo.jpg";
import blueDartBanner from "../assets/blueDartBanner.jpg";
import blueDartMobileBanner from "../assets/blueDartMobileBanner.jpg";
import blueDartTest from "../assets/blueDartTest.jpg";
import "./search.css";

import {
  useAppointmentPast,
  useAppointmentUpcoming,
} from "../services/appointment";
import { GetStringSlot } from "../utils/helpers";
import dayjs from "dayjs";
import {
  GetChat,
  GetPatientProfile,
  GetProfile,
  PostChat,
  getLatestProfile,
} from "../services/auth";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { queryClient } from "../App";
import { getFamilyMembers } from "../services/family";
import { CT, USER_PROFILE } from "../constants";
import { setItem, getItem } from "../services/storage";
import { PatchRCAppointment } from "../services/labtest";
import Select from "react-select";
import logo from "../assets/logoAtkins.svg";
import cocaColaBanner from "../assets/cocaColaBanner.jpg";
import { TextField } from "@mui/material";

interface ServiceCardProps {
  ID: number;
  name: string;
  description: string;
  image: string;
  availed: boolean;
  isLabTest: boolean;
  price: number;
  discountedPrice: number;
  handleAvail: (id: number, isLabTest: boolean) => void;
}

const ServiceCard = ({
  ID,
  name,
  description,
  image,
  availed,
  isLabTest,
  handleAvail,
  price,
  discountedPrice,
}: ServiceCardProps) => {
  const [modal, setModal] = useState<boolean>(false);
  return (
    <>
      <div className="service-card service-card-container">
        <div className="service-card-images">
          <Image className="service-card-image" radius="md" src={image} />
        </div>
        <Box py={12} className="service-card-right-container-box">
          <div className="service-card-right-container">
            <Modal
              title={<Title>{name}</Title>}
              opened={modal}
              onClose={() => setModal(false)}
              centered
              size={"xl"}
            >
              <Box>
                <Text color={"gray"}>{description}</Text>
              </Box>
            </Modal>
            <div className="service-card-price-info-group-mobile">
              <div className="service-card service-card-price-info-section">
                <div className="service-card-header">
                  {/* size="lg" weight={"bold"} */}
                  <Text className="service-card-header-name">{name}</Text>
                  <Text
                    weight={"lighter"}
                    className="service-card-header-address"
                  >
                    {description}
                  </Text>
                </div>
                <div className="service-card-price">
                  {/* weight={"bold"} */}
                  <Text className="service-card-price-heading">Price:</Text>
                  {/* weight={"lighter"} */}
                  <Text className="service-card-price-price">
                    {discountedPrice != 0 && discountedPrice !== null ? (
                      <>
                        ₹ <s>{price}</s> {discountedPrice}
                      </>
                    ) : (
                      "Sponsored"
                    )}
                  </Text>

                  {availed && (
                    <Badge color={"green"} size="md" variant="dot">
                      Availed
                    </Badge>
                  )}
                </div>
              </div>
              {/* {{display:"flex", flexDirection:"column", width:"50%", alignItems:"flex-end", paddingRight:"10%",}} */}
              {/* <div className="service-card-group"> */}
              <Group className="card-button">
                {!availed && (
                  <Link
                    className="card-button-avail"
                    // to={`basic-details/${ID}`}
                    onClick={() => handleAvail(ID, false)}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontWeight: "semibold",
                    }}
                  >
                    <Button my={16} size="sm">
                      <Group spacing="xs">
                        <Text weight="bold">Avail now</Text>
                        <ChevronRight size={16} />
                      </Group>
                    </Button>
                  </Link>
                )}
                <Button
                  className="card-button-avail"
                  style={{ width: "145px" }}
                  size="sm"
                  variant="default"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  Know more <ChevronRight size={16} />
                </Button>
              </Group>
              {/* </div> */}
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

const OriginalServiceCard = ({
  ID,
  name,
  description,
  image,
  availed,
  isLabTest,
  handleAvail,
  price,
  discountedPrice,
}: ServiceCardProps) => {
  const [modal, setModal] = useState<boolean>(false);
  return (
    <>
      <Image height={220} radius="md" src={image} />
      <Box py={12}>
        <Modal
          title={<Title>{name}</Title>}
          opened={modal}
          onClose={() => setModal(false)}
          centered
          size={"xl"}
        >
          <Box>
            <Text color={"gray"}>{description}</Text>
          </Box>
        </Modal>
        <Text size="lg" weight={"bold"}>
          {name}
        </Text>
        <Text weight={"bold"}>
          {discountedPrice != 0 && discountedPrice !== null ? (
            <>
              ₹ <s>{price}</s> {discountedPrice}
            </>
          ) : (
            "Sponsored"
          )}
        </Text>
        {availed && (
          <Badge color={"green"} size="md" variant="dot">
            Availed
          </Badge>
        )}
        <Text weight={"lighter"}>{description}</Text>
        <Group>
          {!availed && (
            <Link
              // to={`basic-details/${ID}`}
              onClick={() => handleAvail(ID, false)}
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: "semibold",
              }}
            >
              <Button my={16} size="sm" id={String(ID)}>
                <Group spacing="xs">
                  <Text weight="bold">Avail now</Text>
                  <ChevronRight size={16} />
                </Group>
              </Button>
            </Link>
          )}
          <Button
            my={16}
            size="sm"
            variant="default"
            onClick={() => {
              setModal(true);
            }}
          >
            Know more <ChevronRight size={16} />
          </Button>
        </Group>
      </Box>
    </>
  );
};

const PartnerImage = ({
  img,
  onClick,
}: {
  img: typeof redcliff;
  onClick: () => void;
}) => (
  <Image
    src={img}
    onClick={onClick}
    sx={(theme) => ({
      width: 160,
      cursor: "pointer",
      filter: "grayscale(1)",
      ":hover": {
        filter: "grayscale(0)",
      },
    })}
  />
);

const LabtestPartners = [
  // {
  //   name: "Thyrocare",
  //   image: thyrocare,
  // },
  { name: "Redcliff", image: redcliff },
];

const Index = () => {
  const upcomingAppointments: any = [];
  const pastAppointments: any = [];
  const [enabled, setEnabled] = useState<boolean>(false);
  const {
    status,
    data: servicesData,
    error,
    isLoading,
  } = useUserServices({
    enabled: enabled,
  });

  const [isLatestLoaded, setIsLatestLoaded] = useState<boolean>(true);

  const [filterData, setFilterData] = useState<any>();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedService, setSelectedService] = useState([]);
  // const [selectedService, setSelectedService] = useState("")
  const [serviceOptions, setServiceOptions] = useState([]);
  const [isAtkins, setIsAtkins] = useState(false);
  const [isCola, setIsCola] = useState(false);
  const [isCeat, setIsCeat] = useState(false);
  const [isBritishTelecom, setIsBritishTelecom] = useState(false);
  const [isTechnip, setIsTechnip] = useState(false);
  const [isTechnip2, setIsTechnip2] = useState(false);
  const [isServier, setIsServier] = useState(false);
  const [isJabil, setIsJabil] = useState(false);
  const [isNovo, setIsNovo] = useState(false);
  const [isHeroHP, setIsHeroHP] = useState(false);
  const [isHeroHC, setIsHeroHC] = useState(false);
  const [isHeroHM, setIsHeroHM] = useState(false);
  const [isRsm, setIsRsm] = useState(false);
  const [searchService, setSearchService] = useState("");
  const [searchService2, setSearchService2] = useState("Radiology test");
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredServices2, setFilteredServices2] = useState([]);
  const [serviceCheck, setServiceCheck] = useState(false);
  const [pathology, setPathology] = useState(false);
  const [isSentine, setIsSentine] = useState(false);
  const [isSony, setIsSony] = useState(false);
  const [isDhl, setIsDhl] = useState(false);
  const [isDhl2, setIsDhl2] = useState(false);
  const [isTata, setIsTata] = useState(false);
  const [isRaheja, setIsRaheja] = useState(false);
  const [isTataPower, setIsTataPower] = useState(false);
  const [isHerbal, setIsHerbal] = useState(false);
  const [isBlueDart, setIsBlueDart] = useState(false);
  const profile = getItem("USER_PROFILE");
  const code = profile?.patientProfile?.clientCode;
  // console.log("id is", profile.patientProfile.id)

  useEffect(() => {
    if (!profile?.patientProfile?.id) {
      return;
    }
    getLatestProfile(profile?.patientProfile?.id)
      .then((res: any) => {
        "phone";

        setItem("USER_PROFILE", { ...res.data, phoneNo: res.data.phoneno });
        if (
          res.data.patientProfile.clientCode === "04XXKN" ||
          res.data.patientProfile.clientCode === "04XXDA"
        ) {
          setIsAtkins(true);
        } else if (res.data.patientProfile.clientCode === "04XXCF") {
          setIsCola(true);
        } else if (
          res.data.patientProfile.clientCode === "04XXCE" ||
          res.data.patientProfile.clientCode === "04XXCQ"
        ) {
          setIsCeat(true);
        } else if (
          res.data.patientProfile.clientCode === "04XXBR" ||
          res.data.patientProfile.clientCode === "04XXBC"
        ) {
          setIsBritishTelecom(true);
        } else if (
          res.data.patientProfile.clientCode === "04XXTH" ||
          res.data.patientProfile.clientCode === "04XXZY"
        ) {
          setIsTechnip(true);
        } else if (res.data.patientProfile.clientCode === "04XXNE") {
          setIsTechnip2(true);
        } else if (res.data.patientProfile.clientCode === "04XXSE") {
          setIsServier(true);
        } else if (
          res.data.patientProfile.clientCode === "04XXJB" ||
          res.data.patientProfile.clientCode === "04XXJA"
        ) {
          setIsJabil(true);
        } else if (res.data.patientProfile.clientCode === "04XXNC") {
          setIsNovo(true);
        } else if (res.data.patientProfile.clientCode === "04XXHM") {
          setIsHeroHM(true);
        } else if (res.data.patientProfile.clientCode === "04XXHP") {
          setIsHeroHP(true);
        } else if (res.data.patientProfile.clientCode === "04XXHC") {
          setIsHeroHC(true);
        } else if (
          res.data.patientProfile.clientCode === "04XXRS" ||
          res.data.patientProfile.clientCode === "04XXMR"
        ) {
          setIsRsm(true);
        } else if (res.data.patientProfile.clientCode === "04XXST") {
          setIsSentine(true);
        } else if (res.data.patientProfile.clientCode === "04XXSO") {
          setIsSony(true);
        } else if (
          res.data.patientProfile.clientCode === "04XXDX" ||
          res.data.patientProfile.clientCode === "04XXDV" ||
          res.data.patientProfile.clientCode === "04XXDS"
        ) {
          setIsDhl(true);
        } else if (res.data.patientProfile.clientCode === "04XXUG") {
          setIsDhl2(true);
        } else if (res.data.patientProfile.clientCode === "04TPDL") {
          setIsTata(true);
        } else if (
          res.data.patientProfile.clientCode === "04KRCA" ||
          res.data.patientProfile.clientCode === "04KRCB" ||
          res.data.patientProfile.clientCode === "04KRCC"
        ) {
          setIsRaheja(true);
        } else if (res.data.patientProfile.clientCode === "04TPMU") {
          setIsTataPower(true);
        } else if (res.data.patientProfile.clientCode === "04HERB") {
          setIsHerbal(true);
        } else if (res.data.patientProfile.clientCode === "04BLUE") {
          setIsBlueDart(true);
        }
        setIsLatestLoaded(false);
      })
      .catch((err: any) => {
        console.log("error is", err);
        setIsLatestLoaded(false);
      });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const categories = new Set();
      servicesData?.services.map((s) => {
        if (
          s.service.category !== "" &&
          s.service.category !== "otherCity" &&
          s.service.category !== "Other city"
        ) {
          categories.add(s.service.category);
        }
      });
      const otherCityServices = servicesData?.services.filter(
        (s) =>
          s.service.category === "otherCity" ||
          s.service.category === "Other city"
      );
      console.log("other city services:", otherCityServices);
      if (otherCityServices && otherCityServices !== undefined) {
        otherCityServices.forEach((s) => {
          categories.add(s.service.category);
        });
      }
      if (categories.size > 0) {
        setCategories([...Array.from(categories)]);
        // setCategories(["All", ...Array.from(categories)]);
      }
      setFilterData(servicesData);
      console.log("service data is", filterData);
    }
  }, [servicesData]);

  const getServiceOptionFromFilteredData = (data: any) => {
    console.log("effect data", data);
    let serviceOptions = [];
    if (data) {
      console.log("hereee", data);
      serviceOptions = data?.services.map((service) => {
        return `${service.service.name}`;
        // {
        //   value:service.service.name,
        //   label:service.service.name,
        // }
      });
      console.log("servieOptions are", serviceOptions);
    }
    return serviceOptions;
  };

  useEffect(() => {
    // && selectedCategory !== "All"
    if (selectedCategory) {
      const filteredServices = servicesData?.services.filter(
        (s) => s.service.category === selectedCategory
      );

      let newFilteredDataObj = { services: filteredServices };

      setFilterData(newFilteredDataObj);
      setServiceOptions(getServiceOptionFromFilteredData(newFilteredDataObj));
      // || selectedCategory === "All"
    } else if (selectedCategory === "") {
      setFilterData(servicesData);
      setServiceOptions(getServiceOptionFromFilteredData(servicesData));
    }
  }, [selectedCategory]);

  // useEffect(() => {
  //   console.log("effect data lol", filterData)

  // },[filterData])

  useEffect(() => {
    if (selectedService) {
      let allServicesDisplay: any = [];
      if (selectedService.length !== 0) {
        for (let i = 0; i < selectedService.length; i++) {
          let filteredServiceArr = servicesData?.services.filter(
            (service: any) => {
              return service.service.name.trim() === selectedService[i].trim();
            }
          );
          if (filteredServiceArr && filteredServiceArr.length > 0) {
            allServicesDisplay.push({ ...filteredServiceArr[0] });
          }
        }
      } else {
        // && selectedCategory !== "All"
        if (selectedCategory) {
          allServicesDisplay = servicesData?.services.filter(
            (s) => s.service.category === selectedCategory
          );
        } else {
          if (servicesData && servicesData.services) {
            allServicesDisplay = [...servicesData?.services];
          }
        }
      }

      setFilterData({ services: allServicesDisplay });
    }
  }, [selectedService]);

  // useEffect(() => {
  //   console.log("selected service is", selectedService)
  //   if(selectedService){
  //     console.log("im here", selectedService)

  //     const displayServices = data?.services.filter((service) => {
  //       console.log("service each is", service.service.name)
  //       return service.service.name.trim() === selectedService?.trim()
  //     })
  //     console.log("display services", displayServices)
  //     setData({services:displayServices})
  //   }
  // },[selectedService])

  const [selectedLabtestService, setSelectedLabtestService] = useState(0);

  const { data: appointmentsData, isLoading: upcomingLoading } =
    useAppointmentUpcoming({ enabled: enabled });
  const { data: appointmentsDataPast, isLoading: pastLoading } =
    useAppointmentPast({ enabled: enabled });

  const [cancel, setCancel] = useState<number>(0);
  const [notCancelable, setNotCancelable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: familyData } = getFamilyMembers({
    enabled: !(upcomingLoading || pastLoading),
  });
  const user = GetProfile();
  const navigate = useNavigate();

  function capitalizeFirstLetter(inputString: string) {
    if (typeof inputString !== "string" || inputString.length === 0) {
      return "";
    }
    const capitalizedString =
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();

    return capitalizedString;
  }

  useEffect(() => {
    const user = GetProfile();
    if (user) {
      GetChat(user?.id)
        .then(() => {
          setEnabled(true);
        })
        .catch((err) => {
          PostChat(user)
            .then(() => {
              setEnabled(true);
            })
            .catch((err) => {
              setEnabled(true);
            });
          console.error(err);
        });
    }
  }, []);

  const handleCancel = async () => {
    setLoading(true);
    try {
      // if redcliff appointment
      console.log(appointmentsData);
      // @ts-ignore
      const appointment = appointmentsData?.appointments?.filter(
        (a) => a.appointment.ID == cancel
      )[0].appointment;
      console.log(appointment);

      const res = await api.post(ApiConfig.appointment.cancelAppointment, {
        appointmentID: cancel,
        status: 3,
        cancellationReason: "1",
      });

      await queryClient.invalidateQueries(["appointments_upcoming"]);
      window.location.reload();

      setCancel(0);
    } catch {}
    setLoading(false);
  };

  const handleAvail = (id: number, isLabTest: boolean) => {
    if (!isLabTest) {
      console.log(navigate);
      window.location.href = `/basic-details/${id}`;
      return;
    }
    // check if patient has email
    const patient = GetPatientProfile();

    if (patient?.patientProfile?.email === "") {
      window.location.href = "/profile?email=true";
    }

    setSelectedLabtestService(id);
  };

  // const temp = categories.map((category) => {
  //   return (
  //     {
  //       value:category,
  //       label:category
  //     }
  //   )
  // })

  // const selectRef = useRef(null);
  // console.log("initial ref", selectRef.current?.state.selectValue)
  // console.log("disabled is",(selectRef.current?.state.selectValue[0]?.value))

  const handleChange = (value) => {
    setSelectedCategory(value);
    setSelectedService([]);
    //console.log("select ref", selectRef.current.state.selectValue)
  };

  const handleSearchChange = (value) => {
    setSearchService(value);
    console.log("before if", filterData);
    if (value === "Sponsored") {
      console.log("inside if", filterData);

      const filteredServices = filterData?.services.filter((service) => {
        return (
          service.service?.discountedPrice === 0 &&
          [
            "PRC",
            "HRA",
            "Doctor consultation",
            "Psychologist consultation",
            "Physiotherapy consultation",
          ].includes(service.service?.type)
        );
      });
      setFilteredServices(filteredServices);
    } else if (value === "Paid") {
      const filteredServices = filterData?.services.filter((service) => {
        return (
          service.service?.discountedPrice != 0 &&
          [
            "PRC",
            "HRA",
            "Doctor consultation",
            "Psychologist consultation",
            "Physiotherapy consultation",
          ].includes(service.service?.type)
        );
      });
      setFilteredServices(filteredServices);
    } else {
      const filteredServices = filterData?.services.filter((service) => {
        return [
          "PRC",
          "HRA",
          "Doctor consultation",
          "Psychologist consultation",
          "Physiotherapy consultation",
        ].includes(service.service?.type);
      });
      setFilteredServices(filteredServices);
    }
  };

  const handleSearchChange2 = (value) => {
    setSearchService2(value);
    console.log("before if", filterData);
    if (value === "Radiology test") {
      console.log("inside if", filterData);
      setPathology(false);
      const filteredServices = filterData?.services.filter((service) => {
        return ["Radiology test"].includes(service.service?.type);
      });
      setFilteredServices2(filteredServices);
    } else if (value === "Pathology test") {
      setPathology(true);
      const filteredServices = filterData?.services.filter((service) => {
        return ["Pathology test"].includes(service.service?.type);
      });
      setFilteredServices2(filteredServices);
    } else {
      console.log("else case", filterData?.services);
      setFilteredServices2(filterData?.services || []);
    }
  };

  const handleAutocompleteInputChange = (value) => {
    let serviceSet = new Set();

    if (selectedService && selectedService.length > 0) {
      let selectServiceSet = new Set();
      selectedService.forEach((s) => {
        selectServiceSet.add(s);
      });
      filterData.services.forEach((item) => {
        if (selectServiceSet.has(item.service.name)) {
          serviceSet.add(item.service.ID);
        }
      });
    }

    // for(let i=0;i<selectedService.length;i++){
    //   for(j=0;j<categoryServices?.length;j++){

    //   }
    // }

    let allServicesDisplay: any = [];
    // && selectedCategory !== "All"
    if (selectedCategory) {
      allServicesDisplay = servicesData?.services.filter(
        (s) => s.service.category === selectedCategory
      );
    } else {
      allServicesDisplay = [...servicesData?.services];
    }

    if (value) {
      allServicesDisplay.forEach((s) => {
        if (s.service.name.toLowerCase().includes(value.toLowerCase())) {
          serviceSet.add(s.service.ID);
        }
      });
    }
    allServicesDisplay = servicesData?.services.filter((s) => {
      return serviceSet.has(s.service.ID);
    });
    setFilterData({ services: allServicesDisplay });
  };

  const [bannerModal, setBannerModal] = useState<boolean>(false);

  const handleBannerModal = () => {
    setBannerModal(true);
  };

  const bookHealthCheckup = () => {
    navigate({ to: "/healthcheckup" });
  };

  const bookConsultations = () => {
    navigate({ to: "/consultations" });
  };

  useEffect(() => {
    if (filterData?.services) {
      const filteredServices = filterData.services.filter((service) =>
        [
          "PRC",
          "HRA",
          "Doctor consultation",
          "Psychologist consultation",
          "Physiotherapy consultation",
        ].includes(service.service?.type)
      );

      const filteredServices2 = filterData.services.filter((service) =>
        ["Pathology test", "Radiology test"].includes(service.service?.type)
      );

      setFilteredServices(filteredServices);
      setFilteredServices2(filteredServices2);
      checkFilterData(filterData);
    }
  }, [filterData]);

  const checkFilterData = (filterData) => {
    if (!filterData || !filterData.services) {
      setServiceCheck(false);
      return false;
    }

    const serviceTypes = filterData.services.map(
      (service) => service.service?.type
    );

    const hasRadiology = serviceTypes.includes("Radiology test");
    const hasPathology = serviceTypes.includes("Pathology test");

    if (hasRadiology && hasPathology) {
      const filteredServices = filterData.services.filter(
        (service) => service.service?.type === "Radiology test"
      );
      setFilteredServices2(filteredServices);
    } else {
      const filteredServices2 = filterData.services.filter((service) =>
        ["Pathology test", "Radiology test"].includes(service.service?.type)
      );
      setFilteredServices2(filteredServices2);
    }

    const result = hasRadiology && hasPathology;
    setServiceCheck(result);

    if (hasPathology && !hasRadiology) {
      setPathology(true);
    }

    return result;
  };

  const startingView = location.pathname == "/";
  const healthCheckup = location.pathname == "/healthcheckup";
  const consultations = location.pathname == "/consultations";
  return (
    <HomeLayout>
      <Modal
        title="Choose a lab partner"
        centered
        opened={selectedLabtestService !== 0}
        onClose={() => setSelectedLabtestService(0)}
      >
        <SimpleGrid cols={2}>
          {LabtestPartners.map((l) => (
            <PartnerImage
              img={l.image}
              onClick={() =>
                navigate({
                  to: `/basic-details/${selectedLabtestService}`,
                  search: { partner: l.name },
                })
              }
            />
          ))}
        </SimpleGrid>
      </Modal>
      <Modal
        title={
          <Text weight="bold">
            Cancellation is not available. Please contact zyla chat for details.
          </Text>
        }
        opened={notCancelable}
        onClose={() => setNotCancelable(false)}
        centered
        size={"md"}
      >
        <Button variant="filled" onClick={() => setNotCancelable(false)}>
          Okay
        </Button>
      </Modal>
      <Modal
        title={
          <Text weight="bold">
            Are you sure you want to cancel this appointment?
          </Text>
        }
        opened={cancel !== 0}
        onClose={() => setCancel(0)}
        centered
        size={"md"}
      >
        <Button
          id="appointment_cancel_home"
          color="red"
          variant="light"
          onClick={() => handleCancel()}
          disabled={loading}
        >
          {loading && <Loader mr="md" color="white" size="xs" />}Cancel
          appointment
        </Button>
        <Button
          id="back_cancel_home"
          color="gray"
          variant="light"
          onClick={() => setCancel(0)}
        >
          Back
        </Button>
      </Modal>
      <Box py={40}>
        {/*
      <Notification mb={36} disallowClose>
        <Link to="/onboarding">
          <Group position="center">
            <Text color="dark" align="center">
              Complete your profile to get started
            </Text>
            <ChevronRight />
          </Group>
        </Link>
      </Notification>
	  */}
        <Title mb={8}>
          Welcome, {capitalizeFirstLetter(user?.patientProfile?.firstName)}
        </Title>

        <Text color={"gray"}>
          You can avail the following services as part of your team
        </Text>
        {isLoading || isLatestLoaded ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 0px",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {isAtkins ||
            isCola ||
            isCeat ||
            isBritishTelecom ||
            isTechnip ||
            isTechnip2 ||
            isServier ||
            isJabil ||
            isNovo ||
            isHeroHP ||
            isHeroHM ||
            isHeroHC ||
            isRsm ||
            isSentine ||
            isSony ||
            isDhl ||
            isDhl2 ||
            isTata ||
            isRaheja ||
            isTataPower ||
            isHerbal ||
            isBlueDart ? (
              <div>
                {isAtkins ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={mobileLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="logo" src={logo} />
                    </MediaQuery>
                  </div>
                ) : isCola ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={mobileColaLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="colaLogo" src={colaLogo} />
                    </MediaQuery>
                  </div>
                ) : isCeat ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={mobileCeatLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={ceatLogo} />
                    </MediaQuery>
                  </div>
                ) : isJabil ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={jabilLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={jabilLogo} />
                    </MediaQuery>
                  </div>
                ) : isNovo ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={novoLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={novoLogo} />
                    </MediaQuery>
                  </div>
                ) : isHeroHM || isHeroHC || isHeroHP ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={heroLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={heroLogo} />
                    </MediaQuery>
                  </div>
                ) : isRsm ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={rsmLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="colaLogo" src={rsmLogo} />
                    </MediaQuery>
                  </div>
                ) : isDhl || isDhl2 ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={dhlLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="colaLogo" src={dhlLogo} />
                    </MediaQuery>
                  </div>
                ) : isSony ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={sonyLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="colaLogo" src={sonyLogo} />
                    </MediaQuery>
                  </div>
                ) : isTata ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={tataLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="colaLogo" src={tataLogo} />
                    </MediaQuery>
                  </div>
                ) : isRaheja ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={rahejaLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img
                        className="ceatLogo"
                        style={{ width: "125px", height: "140px" }}
                        src={rahejaLogo}
                      />
                    </MediaQuery>
                  </div>
                ) : isTataPower ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={tataPowerLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={tataPowerLogo} />
                    </MediaQuery>
                  </div>
                ) : isHerbal ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={herbalifeLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={herbalifeLogo} />
                    </MediaQuery>
                  </div>
                ) : isBlueDart ? (
                  <div>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <img className="logomobile" src={blueDartLogo} />
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <img className="ceatLogo" src={blueDartLogo} />
                    </MediaQuery>
                  </div>
                ) : null}
              </div>
            ) : null}
            {(isAtkins ||
              isCola ||
              isCeat ||
              isBritishTelecom ||
              isTechnip ||
              isTechnip2 ||
              isServier ||
              isJabil ||
              isNovo ||
              isHeroHP ||
              isHeroHM ||
              isHeroHC ||
              isRsm ||
              isSentine ||
              isSony ||
              isDhl ||
              isDhl2 ||
              isTata ||
              isRaheja ||
              isHerbal ||
              isBlueDart) &&
            !pathology ? (
              <div>
                {startingView && (
                  <div className="choice-cards">
                    <a onClick={bookConsultations} className="card">
                      <img
                        src={manCard}
                        alt="Doctor Consultation"
                        className="card-image"
                      />
                      <div className="card-content">
                        <p>Book consultations</p>
                        <ChevronRight className="arrow" size={16} />
                      </div>
                    </a>
                    <a onClick={bookHealthCheckup} className="card">
                      <img
                        src={womenCard}
                        alt="Health Checkup"
                        className="card-image"
                      />
                      <div className="card-content">
                        <p>Book health checkup</p>
                        <ChevronRight className="arrow" size={16} />
                      </div>
                    </a>
                  </div>
                )}
                {location.pathname === "/consultations" && (
                  <div className="container-search">
                    <div className="search-container1">
                      <Autocomplete
                        className="auto"
                        value={searchService}
                        onChange={(event, value) => {
                          handleSearchChange(value);
                        }}
                        options={["Sponsored", "Paid"]}
                        freeSolo={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={
                              searchService
                                ? ""
                                : "Filter by sponsored or paid services"
                            }
                            // label={searchService ? "Filter by sponsored or paid services" : ""}
                            InputLabelProps={{
                              shrink: Boolean(searchService),
                              style: { fontFamily: "Montserrat, sans-serif" },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              style: { fontFamily: "Montserrat, sans-serif" },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                {location.pathname === "/healthcheckup" && (
                  <div className="container">
                    {serviceCheck && (
                      <div className="inside-search-container">
                        <Autocomplete
                          className="auto"
                          options={["Pathology test", "Radiology test"]}
                          getOptionLabel={(option) => option}
                          placeholder="Pick value or enter anything"
                          value={searchService2}
                          onChange={(e, value) => {
                            console.log("Selected value:", value);
                            handleSearchChange2(value);
                          }}
                          clearIcon={null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="search-container">
                      <Autocomplete
                        className="auto"
                        options={categories}
                        getOptionLabel={(option: any) => option}
                        placeholder="Pick value or enter anything"
                        // limit={100}

                        value={selectedCategory}
                        // maxDropdownHeight={200}
                        onChange={(e, value) => {
                          console.log("value is is is s", value);
                          handleChange(value);
                        }}
                        clearIcon={null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select city for lab center"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />

                      {/* <Autocomplete
                    
                    className="auto"
                    options={serviceOptions}
                    getOptionLabel={(option:any) => option}
        
                    
                    // limit={100}
                   
                    value={selectedService}
                    // maxDropdownHeight={200}
                    onChange={(e,value) => 
                      {
                        setSelectedService(value)
                      }
                    }
                    clearIcon={null}
                    disabled={!selectedCategory}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        
                        label="Filter further by locality"
                        inputProps={{
                          ...params.inputProps,
                          
                        }}
                  
                  />
                    )}
                    /> */}
                      <Autocomplete
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value) {
                            console.log("e is", e.target.value);
                            handleAutocompleteInputChange(e.target.value);
                          }
                        }}
                        multiple={true}
                        className="auto"
                        options={serviceOptions}
                        getOptionLabel={(option: any) => option}
                        // limit={100}

                        value={selectedService}
                        // maxDropdownHeight={200}
                        onChange={(e, value) => {
                          console.log("value heh lol", value);
                          setSelectedService(value);
                        }}
                        clearIcon={null}
                        disabled={!selectedCategory}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search further by locality"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </div>

                    {isAtkins ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={banner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={mobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isCola ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={cocaColaBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={cocaColaMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isCeat ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={ceatBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={ceatMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isBritishTelecom ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={britishTelecomBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={britishTelecomMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isTechnip ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={technipBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={technipMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isTechnip2 ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={technipBanner2}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={technipMobileBanner2}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isServier ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={servierBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={servierMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isJabil ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={jabilBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={jabilMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isNovo ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={novoBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={novoMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isHeroHM || isHeroHC || isHeroHP ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={heroBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={heroMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isRsm ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={rsmBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={rsmMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isSentine ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={sentineBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={sentineMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isSony ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={sonyBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={sonyMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isDhl ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={dhlBanner2}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={dhlMobileBanner2}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isDhl2 ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={dhlBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={dhlMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isTata ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={tataBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={tataMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isRaheja ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={rahejaBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={rahejaMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isHerbal ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={herbalifeBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={herbalifeMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : isBlueDart ? (
                      <>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={blueDartBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: "none" }}
                        >
                          <img
                            className="banner-image"
                            src={blueDartMobileBanner}
                            onClick={() => setBannerModal(true)}
                          />
                        </MediaQuery>
                      </>
                    ) : (
                      <></>
                    )}
                    <Modal
                      opened={bannerModal}
                      onClose={() => setBannerModal(false)}
                      centered
                      size={"xl"}
                      overflow="inside"
                    >
                      {isAtkins ? (
                        <Box>
                          <Title order={2}>Male package at centre :</Title>
                          <Text color="gray"> - General exam </Text>
                          <Text color="gray"> - Diabetes screening</Text>
                          <Text color="gray"> - Lipid screening</Text>
                          <Text color={"gray"}> - Liver screening</Text>
                          <Text color={"gray"}> - Kidney screening</Text>
                          <Text color={"gray"}> - Calcium test</Text>
                          <Text color={"gray"}> - Thyroid test (TSH)</Text>
                          <Text color={"gray"}>
                            {" "}
                            - Urine routine and microscopy
                          </Text>
                          <Text color={"gray"}> - Stool examination</Text>
                          <Text color={"gray"}> - Chest X-ray</Text>
                          <Text color={"gray"}> - ECG</Text>
                          <Text color={"gray"}> - TMT/2D ECHO</Text>
                          <Text color={"gray"}> - Ultrasound abdomen</Text>

                          <Title order={2}>Female package at centre :</Title>
                          <Text color={"gray"}> - General exam </Text>
                          <Text color={"gray"}> - Diabetes screening</Text>
                          <Text color={"gray"}> - Lipid screening</Text>
                          <Text color={"gray"}> - Liver screening</Text>
                          <Text color={"gray"}> - Kidney screening</Text>
                          <Text color={"gray"}> - Calcium test</Text>
                          <Text color={"gray"}>
                            {" "}
                            - Thyroid test (TSH, T3 & T4)
                          </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Urine routine and microscopy
                          </Text>
                          <Text color={"gray"}> - Stool examination</Text>
                          <Text color={"gray"}> - Chest X-ray</Text>
                          <Text color={"gray"}> - ECG</Text>
                          <Text color={"gray"}> - TMT/2D ECHO</Text>
                          <Text color={"gray"}> - Ultrasound abdomen</Text>
                          <Text color={"gray"}> - PAP smear</Text>
                          <Text color={"gray"}> - Mammography</Text>
                        </Box>
                      ) : isCola ? (
                        <Box>
                          <Title order={2}>Offerings for Male users :</Title>
                          <Text color="gray">
                            {" "}
                            - Blood grouping & Rh factor{" "}
                          </Text>
                          <Text color="gray">
                            {" "}
                            - Complete blood count with ESR
                          </Text>
                          <Text color="gray">- Diabetes screening</Text>
                          <Text color="gray"> - Lipid screening</Text>
                          <Text color={"gray"}> - Liver screening</Text>
                          <Text color={"gray"}> - Kidney screening</Text>
                          <Text color={"gray"}> - Vitamin profile</Text>
                          <Text color={"gray"}> - Anemia profiling</Text>
                          <Text color={"gray"}> - Thyroid profile</Text>
                          <Text color={"gray"}>
                            {" "}
                            - Urine routine and microscopy
                          </Text>
                          <Text color={"gray"}> - Stool examination</Text>
                          <Text color={"gray"}> - Calcium test</Text>
                          <Text color={"gray"}> - HIV 1 & 2 test</Text>
                          <Text color={"gray"}>
                            {" "}
                            - HbSAg (Hepatitis B) test
                          </Text>
                          <Text color={"gray"}> - PSA test</Text>
                          <Text color={"gray"}> - ECG</Text>
                          <Text color={"gray"}>
                            {" "}
                            - Spirometry/Pulmonary functin test
                          </Text>
                          <Text color={"gray"}> - Chest X-ray</Text>
                          <Text color={"gray"}> - Ultrasound abdomen</Text>
                          <Text color={"gray"}> - TMT</Text>

                          <Title order={2}>Offerings for Female users :</Title>
                          <Text color={"gray"}>
                            {" "}
                            - Blood grouping & Rh factor
                          </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Complete blood count with ESR
                          </Text>
                          <Text color={"gray"}> - Diabetes screening</Text>
                          <Text color={"gray"}> - Lipid screening</Text>
                          <Text color={"gray"}> - Liver screening</Text>
                          <Text color={"gray"}> - Kidney screening</Text>
                          <Text color={"gray"}> - Vitamin profile</Text>
                          <Text color={"gray"}> - Anemia profiling</Text>
                          <Text color={"gray"}> - Thyroid profile</Text>
                          <Text color={"gray"}>
                            {" "}
                            - Urine routine and microscopy
                          </Text>
                          <Text color={"gray"}> - Stool examination</Text>
                          <Text color={"gray"}> - Calcium test</Text>
                          <Text color={"gray"}> - HIV 1 & 2 test</Text>
                          <Text color={"gray"}>
                            {" "}
                            - HbsAg (Hepatitis B) test
                          </Text>
                          <Text color={"gray"}> - PAP smear</Text>
                          <Text color={"gray"}> - Mammography</Text>
                          <Text color={"gray"}> - ECG</Text>
                          <Text color={"gray"}>
                            {" "}
                            - Spirometry/Pulmonary function test
                          </Text>
                          <Text color={"gray"}> - Chest X-ray</Text>
                          <Text color={"gray"}> - Ultrasound abdomen</Text>
                          <Text color={"gray"}> - TMT</Text>
                        </Box>
                      ) : isCeat ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={ceatTests}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isBritishTelecom ? (
                        <Box>
                          <Title order={2}>Male package at centre :</Title>
                          <Text color={"gray"}> - Vitals </Text>
                          <Text color={"gray"}> - Sugar profile </Text>
                          <Text color={"gray"}> - Lipid profile </Text>
                          <Text color={"gray"}> - Thyroid </Text>
                          <Text color={"gray"}> - Kidney test </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Complete blood count (CBC){" "}
                          </Text>
                          <Text color={"gray"}> - Blood work </Text>
                          <Text color={"gray"}> - Urine routine </Text>
                          <Text color={"gray"}> - Liver profile </Text>
                          <Text color={"gray"}> - Vitamin profile </Text>
                          <Text color={"gray"}> - Lung function </Text>
                          <Text color={"gray"}> - Cardiac screen </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Radiology- USG whole abdomen{" "}
                          </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Radiology- X-Ray chest & spine (for male above 40
                            yrs only){" "}
                          </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Cancer screen- prostate specific antigen (PSA)
                            (for male above 40 yrs only){" "}
                          </Text>

                          <Title order={2} style={{ marginTop: "40px" }}>
                            Female package at centre :
                          </Title>
                          <Text color={"gray"}> - Vitals </Text>
                          <Text color={"gray"}> - Sugar profile </Text>
                          <Text color={"gray"}> - Lipid profile </Text>
                          <Text color={"gray"}> - Thyroid profile </Text>
                          <Text color={"gray"}> - Kidney profile </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Complete blood count (CBC){" "}
                          </Text>
                          <Text color={"gray"}> - Blood work </Text>
                          <Text color={"gray"}> - Urine routine </Text>
                          <Text color={"gray"}> - Liver profile </Text>
                          <Text color={"gray"}> - Vitamin profile </Text>
                          <Text color={"gray"}> - Lung function </Text>
                          <Text color={"gray"}> - Cardiac screen </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Radiology- USG whole abdomen{" "}
                          </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Radiology- X-Ray chest & spine (for female above
                            40 yrs only){" "}
                          </Text>
                          <Text color={"gray"}>
                            {" "}
                            - Cancer screen- conventional PAP smear,
                            sono-mammography{" "}
                          </Text>
                        </Box>
                      ) : isTechnip ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={technipTests}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isTechnip2 ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={technip2Modal}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isServier ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={servierModal}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isNovo ? (
                        <Box>
                          <Title order={2}>
                            Offerings for users who are below 35 years of age:
                          </Title>
                          <Text color="gray"> - Hemogram tests</Text>
                          <Text color="gray"> - Lipid profile</Text>
                          <Text color="gray"> - Thyroid profile total</Text>
                          <Text color="gray"> - Diabetic screen</Text>
                          <Text color="gray"> - Kidney panel</Text>
                          <Text color="gray"> - Liver function test</Text>
                          <Text color="gray"> - Iron deficiency profile</Text>
                          <Text color="gray"> - Vitamin B12 & vitamin D3</Text>
                          <Text color="gray"> - ECG</Text>
                          <Text color="gray"> - X-ray</Text>

                          <Title order={2}>
                            Offerings for users who are above 35 years of age:
                          </Title>
                          <Text color="gray"> - Hemogram tests</Text>
                          <Text color="gray"> - Urine routine</Text>
                          <Text color="gray"> - Stool routine</Text>
                          <Text color="gray">
                            {" "}
                            - Blood sugar - fasting & post prandial + HBA1C
                          </Text>
                          <Text color="gray"> - Lipid profile</Text>
                          <Text color="gray"> - LFT: SGPT only</Text>
                          <Text color="gray">
                            {" "}
                            - Kidney test: S. creatinine
                          </Text>
                          <Text color="gray"> - Thyroid (TSH)</Text>
                          <Text color="gray"> - Chest X-ray</Text>
                          <Text color="gray">
                            {" "}
                            - Stress test (TMT) / 2D Echo
                          </Text>
                          <Text color="gray"> - USG whole abdomen</Text>
                          <Text color="gray"> - PSA/ PAP smear</Text>
                          <Text color="gray"> - Physical exam</Text>
                          <Text color="gray">
                            {" "}
                            - Consultation ophthalmologist (eye) check up
                          </Text>
                          <Text color="gray">
                            {" "}
                            - Gynaecologic exam & consultation
                          </Text>
                        </Box>
                      ) : isJabil ? (
                        <Box>
                          <Title order={2}>Offerings for Male users :</Title>
                          <Text color="gray">
                            {" "}
                            - Complete hemogram with ESR
                          </Text>
                          <Text color="gray">
                            {" "}
                            - Urine routine & microscopy
                          </Text>
                          <Text color="gray"> - Diabetes profile</Text>
                          <Text color="gray"> - Kidney profile</Text>
                          <Text color="gray"> - Liver profile</Text>
                          <Text color="gray"> - Lipid profile</Text>
                          <Text color="gray">
                            {" "}
                            - Prostate-Specific antigen test
                          </Text>
                          <Text color="gray"> - Thyroid profile</Text>
                          <Text color="gray"> - ECG</Text>
                          <Text color="gray"> - Chest X-ray</Text>
                          <Text color="gray"> - USG abdomen</Text>
                          <Text color="gray"> - TMT</Text>
                          <Text color="gray"> - 2D echo</Text>
                          <Text color="gray"> - Vitamin D3</Text>
                          <Text color="gray"> - Vitamin B12</Text>
                          <Text color="gray">
                            {" "}
                            - Physician examination with BP, BMI
                          </Text>

                          <Title order={2}>Offerings for Female users :</Title>
                          <Text color="gray">
                            {" "}
                            - Complete hemogram with ESR
                          </Text>
                          <Text color="gray">
                            {" "}
                            - Urine routine & microscopy
                          </Text>
                          <Text color="gray"> - Diabetes profile</Text>
                          <Text color="gray"> - Kidney profile</Text>
                          <Text color="gray"> - Liver profile</Text>
                          <Text color="gray"> - Lipid profile</Text>
                          <Text color="gray"> - Thyroid profile</Text>
                          <Text color="gray"> - ECG</Text>
                          <Text color="gray"> - Chest X ray</Text>
                          <Text color="gray"> - USG abdomen</Text>
                          <Text color="gray"> - Sonomammography</Text>
                          <Text color="gray"> - TMT</Text>
                          <Text color="gray"> - 2D echo</Text>
                          <Text color="gray"> - Vitamin D3</Text>
                          <Text color="gray"> - Vitamin B12</Text>
                          <Text color="gray">
                            {" "}
                            - Physician examination with BP, BMI
                          </Text>
                        </Box>
                      ) : isHeroHP || isHeroHM || isHeroHC ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={heroTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isRsm ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={rsmTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isSentine ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={sentineTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isSony ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={sonyTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isDhl ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={dhlTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isDhl2 ? (
                        <Box>
                          <Title order={2}>
                            Annual health checkup package includes :
                          </Title>
                          <Text color="gray"> - Blood bank</Text>
                          <Text color="gray"> - Haematology</Text>
                          <Text color="gray"> - Urinalysis</Text>
                          <Text color="gray"> - Diabetes test</Text>
                          <Text color="gray"> - Kidney function test</Text>
                          <Text color="gray"> - Liver function test</Text>
                          <Text color="gray"> - Lipid profile</Text>
                          <Text color="gray">
                            {" "}
                            - Pulmonary functioning test
                          </Text>
                          <Text color="gray"> - Audiometry pure tone</Text>
                          <Text color="gray"> - Ultrasound whole abdomen</Text>
                          <Text color="gray"> - 2D Echo</Text>
                          <Text color="gray"> - Height, Weight, BP, BMI</Text>
                          <Text color="gray"> - Eye check-up</Text>
                          <Text color="gray">
                            {" "}
                            - General physical examination
                          </Text>
                        </Box>
                      ) : isTata ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={tataTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isRaheja ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={rahejaTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isHerbal ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={herbalifeTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : isBlueDart ? (
                        <>
                          <MediaQuery styles={{ display: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="test-image"
                                src={blueDartTest}
                                height="100%"
                                width="100%"
                              />
                            </div>
                          </MediaQuery>
                        </>
                      ) : (
                        <></>
                      )}
                    </Modal>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {startingView && (
                  <div className="choice-cards">
                    <a onClick={bookConsultations} className="card">
                      <img
                        src={manCard}
                        alt="Doctor Consultation"
                        className="card-image"
                      />
                      <div className="card-content">
                        <p>Book consultations</p>
                        <ChevronRight className="arrow" size={16} />
                      </div>
                    </a>
                    <a onClick={bookHealthCheckup} className="card">
                      <img
                        src={womenCard}
                        alt="Health Checkup"
                        className="card-image"
                      />
                      <div className="card-content">
                        <p>Book health checkup</p>
                        <ChevronRight className="arrow" size={16} />
                      </div>
                    </a>
                  </div>
                )}
                {location.pathname === "/consultations" && (
                  <div className="container-search">
                    <div className="search-container1">
                      <Autocomplete
                        className="auto"
                        value={searchService}
                        onChange={(event, value) => handleSearchChange(value)}
                        options={["Sponsored", "Paid"]}
                        freeSolo={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={
                              searchService
                                ? ""
                                : "Filter by sponsored or paid services"
                            }
                            // label={searchService ? "Filter by sponsored or paid services" : ""}
                            InputLabelProps={{
                              shrink: Boolean(searchService),
                              style: { fontFamily: "Montserrat, sans-serif" },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              style: { fontFamily: "Montserrat, sans-serif" },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                {location.pathname === "/healthcheckup" && (
                  <div className="container">
                    {serviceCheck && (
                      <div className="inside-search-container">
                        <Autocomplete
                          className="auto"
                          options={["Pathology test", "Radiology test"]}
                          getOptionLabel={(option) => option}
                          placeholder="Pick value or enter anything"
                          value={searchService2}
                          onChange={(e, value) => {
                            console.log("Selected value:", value);
                            handleSearchChange2(value);
                          }}
                          clearIcon={null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* {categories.length && (
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  marginTop: "20px",
                  flexWrap: "wrap",
                }}
              >
                {categories.map((c) => (
                  <Button
                    radius={"xl"}
                    uppercase
                    variant={`${selectedCategory === c ? "filled" : "outline"}`}
                    onClick={() => setSelectedCategory(c)}
                    id={c}
                  >
                    {c}
                  </Button>
                ))}
              </div>
            )} */}
              </div>
            )}

            {isAtkins ||
            isCola ||
            isCeat ||
            isBritishTelecom ||
            isTechnip ||
            isTechnip2 ||
            isServier ||
            isJabil ||
            isNovo ||
            isHeroHP ||
            isHeroHC ||
            isHeroHM ||
            isRsm ||
            isSentine ||
            isSony ||
            isDhl ||
            isDhl2 ||
            isTata ||
            isRaheja ||
            isHerbal ||
            isBlueDart ? (
              <Grid my={32}>
                {location.pathname === "/consultations" &&
                filteredServices.length > 0
                  ? filteredServices.map((service) => (
                      <Grid.Col xs={12} span={12} key={service.service?.ID}>
                        <ServiceCard
                          handleAvail={handleAvail}
                          availed={service.availed}
                          {...service.service}
                        />
                      </Grid.Col>
                    ))
                  : null}
                {location.pathname === "/healthcheckup" &&
                filteredServices2.length > 0
                  ? filteredServices2.map((service) => (
                      <Grid.Col xs={12} span={12} key={service.service?.ID}>
                        <ServiceCard
                          handleAvail={handleAvail}
                          availed={service.availed}
                          {...service.service}
                        />
                      </Grid.Col>
                    ))
                  : null}
                {((consultations && filteredServices.length === 0) ||
                  (healthCheckup && filteredServices2.length === 0)) &&
                  !startingView && (
                    <Grid.Col xs={12} span={12} style={{ textAlign: "center" }}>
                      <img src={noService} alt="No services available" />
                      <p className="service-text">No services found</p>
                    </Grid.Col>
                  )}
              </Grid>
            ) : (
              <Grid my={32}>
                {location.pathname === "/consultations" &&
                filteredServices.length > 0
                  ? filteredServices.map((service, index) => (
                      <Grid.Col xs={12} span={12} key={service.service?.ID}>
                        <div id={`card-${index}`}>
                          <ServiceCard
                            handleAvail={handleAvail}
                            availed={service.availed}
                            {...service.service}
                          />
                        </div>
                      </Grid.Col>
                    ))
                  : null}
                {location.pathname === "/healthcheckup" &&
                filteredServices2.length > 0
                  ? filteredServices2.map((service, index) => (
                      <Grid.Col xs={12} span={12} key={service.service?.ID}>
                        <div id={`card-${index}`}>
                          <ServiceCard
                            handleAvail={handleAvail}
                            availed={service.availed}
                            {...service.service}
                          />
                        </div>
                      </Grid.Col>
                    ))
                  : null}
                {((consultations && filteredServices.length === 0) ||
                  (healthCheckup && filteredServices2.length === 0)) &&
                  !startingView && (
                    <Grid.Col xs={12} span={12} style={{ textAlign: "center" }}>
                      <img src={noService} alt="No services available" />
                      <p className="service-text">No services found</p>
                    </Grid.Col>
                  )}
              </Grid>
            )}
          </>
        )}
        {!consultations && !healthCheckup && (
          <div>
            <Text size="lg" weight={"bold"} pb={20}>
              Upcoming appointments
            </Text>
            <Grid>
              {appointmentsData?.appointments?.length == null && (
                <EmptyAppointment />
              )}
              {appointmentsData?.appointments?.map((e: any, index: any) => (
                <Grid.Col xs={4} span={12} key={e?.appointment?.ID}>
                  <AppointmentCard
                    index={index}
                    orderId={e?.appointment?.paymentOrderId}
                    onCancel={(enabled) => {
                      if (!enabled) {
                        setNotCancelable(true);
                      } else {
                        setCancel(e?.appointment?.ID);
                      }
                    }}
                    onReschedule={() =>
                      navigate({ to: "/reschedule/" + e?.appointment?.ID })
                    }
                    paymentOrderId={e?.appointment?.paymentOrderId}
                    status={e?.appointment?.status}
                    serviceTitle={e?.service?.name}
                    isLabTest={e?.service?.isLabTest}
                    name={
                      e?.appointment?.bookedFor === 0
                        ? "Self"
                        : `${
                            familyData?.filter(
                              (f) =>
                                f.memberPatientId === e?.appointment?.bookedFor
                            )[0]?.firstName
                          } ${
                            familyData?.filter(
                              (f) =>
                                f.memberPatientId === e?.appointment?.bookedFor
                            )[0]?.lastName
                          }` || "Self"
                    }
                    date={dayjs(e?.appointment?.appointmentDate).format(
                      "DD, MMM YYYY"
                    )}
                    timeSlot={GetStringSlot(e?.appointment?.timeSlot)}
                  />
                </Grid.Col>
              ))}
            </Grid>

            <Text size="lg" weight={"bold"} mt={40} pb={20}>
              Past appointments
            </Text>
            <Grid>
              {appointmentsDataPast?.appointments?.length == null && (
                <EmptyAppointment />
              )}
              {appointmentsDataPast?.appointments?.map((e: any) => (
                <Grid.Col xs={4} span={12} key={e?.appointment?.ID}>
                  <AppointmentCard
                    orderId={e?.appointment?.paymentOrderId}
                    status={e?.appointment?.status}
                    past
                    serviceTitle={e?.service?.name}
                    name={
                      e?.appointment?.bookedFor === 0
                        ? "Self"
                        : `${
                            familyData?.filter(
                              (f) =>
                                f.memberPatientId === e?.appointment?.bookedFor
                            )[0]?.firstName
                          } ${
                            familyData?.filter(
                              (f) =>
                                f.memberPatientId === e?.appointment?.bookedFor
                            )[0]?.lastName
                          }` || "Self"
                    }
                    date={dayjs(e?.appointment?.appointmentDate).format(
                      "DD, MMM YYYY"
                    )}
                    timeSlot={GetStringSlot(e?.appointment?.timeSlot)}
                    link={e?.appointment?.ID}
                  />
                </Grid.Col>
              ))}
            </Grid>
          </div>
        )}
      </Box>{" "}
    </HomeLayout>
  );
};

export default Index;
